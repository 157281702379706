import React, {useContext, useState, useEffect, StrictMode} from 'react'
import { useNavigate ,BrowserRouter as Router} from 'react-router-dom';


//material
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import partnerAPI from '../../../Services/partnerAPI';
import { ToastContainer, toast } from 'react-toastify';


export default function PriceToken(props){
    useEffect(() => { 
    const colorsLayout = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
    if(colorsLayout){
    setColorInsideButtons(colorsLayout.colorsLayout.colorInsideButtons);

  }
  }, []);
    const [colorInsideButtons , setColorInsideButtons] =useState ();
    const [txtRetability, setTxtRetability] = useState('')
    const [txtClosingMonth, setTxtClosingMonth] = useState('')
    const [txDatePayments, setTxtDatePayments] = useState(new Date);
    const [boolIPCA, setBoolIPCA] = useState(false)  
    const [precoInauguracao, setPrecoInauguracao] = useState('R$ 0,00');
    const [precoFixo, setPrecoFixo] = useState('R$ 0,00');


    const label = { inputProps: { 'aria-label': 'Size switch demo' } };


    

    const handleClickSalvar = async (e) => {
        e.preventDefault();
         const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };
        partnerAPI.post("/Partner/PriceToken",{}, config)
        .then((result)=>{
            console.log(result, 'puxando dados do price Fix')
        })
        .catch((error)=>{
            console.log(error, 'deu erro')
        })

       let valorFixo = precoFixo
        .replace('R$', '') // Remove o símbolo de R$
        .replace(/\s/g, '') // Remove espaços em branco
        .replace(',', '.'); // Substitui a vírgula por ponto para ser um valor numérico válido
       let valorInauguracao = precoInauguracao
        .replace('R$', '') // Remove o símbolo de R$
        .replace(/\s/g, '') // Remove espaços em branco
        .replace(',', '.'); // Substitui a vírgula por ponto para ser um valor numérico válido

        let precoI = parseFloat(valorInauguracao);
        let precoF = parseFloat(valorFixo)

    if (precoI &&  precoF)
    {
        console.log(precoF , precoI, 'valoress')        
        toast.error('Envie somente um campo');
       
    }
    else if (!precoI &&  !precoF )
    {
        console.log(precoI, precoF )
        toast.warning('Preencha um dos campos');
    }
    else if ( precoI ===   0 )
    {
        console.log(precoF , 'preco fixo')

        const dados = {
        priceInauguration: 0,  
        priceFix: precoF,           
        cdAsset: 1010             
        };

        try{
            const enviarPriceFixSucess = await partnerAPI.post("/Partner/PriceToken", dados, config);
            if(enviarPriceFixSucess)
            {
                toast.success('Preço Fixo Enviado Com Sucesso');
            }

        }catch(error){
            console.log(error ,'deu erro');
            toast.error('Falha ao enviar Preço Fixo.');
        }
    }
    else if ( precoF ===   0 )
    {
        console.log(precoF , 'preco inauguraçao')

        const dados = {
        priceInauguration: precoI,  
        priceFix: 0,           
        cdAsset: 1010  
        };

        try{
            const enviarPriceInaugurationSucess = await partnerAPI.post("/Partner/PriceToken", dados, config);
            if(enviarPriceInaugurationSucess)
            {
                toast.success('Preço de Inauguração Enviado Com Sucesso');
                
            }    
        }catch(error){
            console.log(error, 'erro ao enviar preço de inaugurção')
            toast.error('Falha ao enviar Preço de Inauguração.')
        }
     }


                
        // if(txtRetability === null || txtRetability <= 0){
        //     return window.alert('O campo Rentabilidade deve ser maior que zero');
        // }
        // if(txtClosingMonth === null || txtClosingMonth <= 0){
        //     return window.alert('O campo Meses Fechamento deve ser maior que zero');
        // }

        // let date = new Date(txDatePayments);         
        // if(!isNaN(date) === false){
        //     return window.alert('O campo Data Inicio deve ser preenchido');
        // }

        // var contract = sessionStorage.getItem('contract');        
        // if(contract === null || contract === "" || contract === "null"){
        //     return window.alert('Acesso não permitido para fazer determinada ação.');
        // }

        // var data = {
        //     'Rentability': txtRetability,
        //     'ClosingMonth': txtClosingMonth,
        //     'DatePayments': txDatePayments,
        //     'Ipca': boolIPCA,
        //     'ContractHash': contract
        // };
        
        // // agora fica para fazer a requisição para o back-end 
        // const config = {
        //     headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
        //     },
        // };       

        // partnerAPI.post("Partner/Debenture_Update", data,config)
        // .then((res) => {
        //     console.log(res.data, 'SUCCESS');

        //     if(res.data.success === false){
        //         let text = "";
        //         for(let prop in res.data.erros){
        //             text += `${prop} : ${res.data.erros[prop]}` + "\n";
        //         }                
        //         window.alert(text);                                                 
        //     }else{
        //         window.alert('Fluxo processado com sucesso!');                                
        //     }
        // })            
        // .catch(error => {
        //     window.alert('Ocorreu algum erro no processamento!');                
        //     console.log(error.response, 'ERRO');
        // });

    };

        const handleInputChangeI = (event) => {
    const novoValor = formatCurrency(event.target.value.replace(/[^0-9.,]/g, ''));
    setPrecoInauguracao(novoValor);
   
  };

    const handleInputChangeF = (event) => {
    const novoValorF = formatCurrency(event.target.value.replace(/[^0-9.,]/g, ''));    
    setPrecoFixo(novoValorF);
  };

    const formatCurrency = (value) => {
    // Remove caracteres não numéricos da string
    const numericValue = value.replace(/\D/g, '');

    // Adiciona o símbolo R$ e formata a string com a máscara desejada
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
        if (i === numericValue.length - 2) {
            formattedValue += '.';
        }
        formattedValue += numericValue[i];
    }

    // Formata o número como moeda brasileira
    return Number(formattedValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }



    return (
        <div className='row'>
            <div className='col-sm-5' style={{marginLeft: 25}}>
                <div className='container my-5 card'>
                
                <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                    <h5>Precificação de Token</h5>
                </div>

                <div className='container'>
                    <div className='row border border-2' style={{marginBottom: 15, borderRadius: 20}}>
                        <div className=''>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12'                                    
                                    label="Preço de Inauguração" 
                                    variant="standard" 
                                    value={precoInauguracao} 
                                    onChange={(e) => handleInputChangeI(e)} 
                                />
                            </div>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12'                                    
                                    label="Preço Fixo" 
                                    variant="standard" 
                                    value={precoFixo} 
                                    onChange={(e) => handleInputChangeF(e)} 
                                />
                            </div>
                        </div>                      
                        <div style={{position:"relative"}}>
                            <div style={{float:"right", margin:"10px"}}>                                                        
                                <Button 
                                    style ={{backgroundColor: colorInsideButtons}}
                                    variant="contained"
                                    onClick={handleClickSalvar}
                                >
                                Salvar
                                </Button>                            
                            </div> 
                        </div>
                    </div>
                                  <ToastContainer />
                </div>
                </div>
            </div>
            <div className='col-sm-6'></div>
        </div>

    );
}