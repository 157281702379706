import React, { useContext, useState } from "react";
import { useNavigate, BrowserRouter as Router } from "react-router-dom";

//material
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CardActionArea from "@mui/material/CardActionArea";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TrendingUpIcon from "@mui/icons-material/TrendingUp"; //rentabilidade
import AddIcon from "@mui/icons-material/Add"; // adicionar
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined"; //queimar
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"; //contrato
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

export default function ListSmartContract(props) {
  const navigate = useNavigate();
  const [off, setOff] = useState(true);

  return (
    <div className="my-5">
      <div style={{ marginTop: 10 }}>
        <div style={{ marginBottom: 25 }}>
          <h5>Configurações do Token</h5>
        </div>
        <Box
          className="listaConfig"
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 8,
          }}
        >
          <List>
            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/dados")}>
                <ListItemButton>
                  <MonetizationOnOutlinedIcon />
                  <ListItemText
                    primary="Dados do Token"
                    className="col-sm-6"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea
                onClick={() =>
                  navigate("/adicionarTokens", {
                    state: {
                      type: 0,
                    },
                  })
                }
              >
                <ListItemButton>
                  <AddIcon />
                  <ListItemText
                    primary="Adicionar Token"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea
                onClick={() =>
                  navigate("/queimarTokens", {
                    state: {
                      type: 1,
                    },
                  })
                }
              >
                <ListItemButton>
                  <WhatshotOutlinedIcon />
                  <ListItemText
                    primary="Queimar Token"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/price")}>
                <ListItemButton>
                  <SellOutlinedIcon />
                  <ListItemText
                    primary="Preço do Token"
                    className="col-sm-6"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/garantia")}>
                <ListItemButton>
                  <ArticleOutlinedIcon />
                  <ListItemText
                    primary="Garantia do Token"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/distribuicao")}>
                <ListItemButton>
                  <TrendingUpIcon />
                  <ListItemText
                    primary="Rentabilidade do Token"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/transacao")}>
                <ListItemButton>                  
                  <MoneyOffIcon />
                  <ListItemText
                    primary="Bloqueio de Transações"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/taxaTransacao")}>
                <ListItemButton>
                   <CurrencyExchangeIcon />
                  <ListItemText
                    primary="Taxa de Transação"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

                        <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/taxaSwap")}>
                <ListItemButton>
                  <CurrencyBitcoinIcon />
                  <ListItemText
                    primary="Taxa de Swap"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>



            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/contratoInteligente")}>
                <ListItemButton>
                  <TipsAndUpdatesOutlinedIcon />
                  <ListItemText
                    primary="Contrato Inteligente"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>

            <ListItem disablePadding>
              <CardActionArea onClick={() => navigate("/configLayout")}>
                <ListItemButton>
                  <ColorLensIcon />
                  <ListItemText
                    primary="Configuração do Layout"
                    style={{ marginLeft: 15 }}
                  />
                </ListItemButton>
              </CardActionArea>
            </ListItem>
          </List>
        </Box>
      </div>
    </div>
  );
}
