import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <>
      <div className='.mobile-hide'>
        <App/>
      </div>

        <div className="desktop-hide logomobile">
          <div className="text-center container">
              <Card>
                <CardContent>
                  <h3>Baixe o App</h3>
                  <p>Baixe agora o aplicativo pela <b>Google Play</b> ou <b>App Store</b> no seu celular ou tablet.</p>
                  <p>Faça sua conta e Receba seu Cartao de Criptomoeda</p>

                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.ciPay.bank">
                      <img src="Assets/img/06-baixeagora_googleplay.png"  />
                    </a>
                  </div>
                  <div>
                    <img src="Assets/img/06-baixeagora_applestore.png"  />
                  </div>  
                </CardContent>
              </Card>

          </div>
        </div>

    </>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
