import React , {useState, useContext, useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import partnerAPI from '../../../Services/partnerAPI';
import authContext from '../../../Context/authContext';

//material
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import CardActionArea from '@mui/material/CardActionArea';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
//import InboxIcon from '@mui/icons-material/Inbox';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };

export default function Perfil(props){
    useEffect(() => {
        buscarMeuEndereco();
        marginbottomdynamic();
        const colorsLayout = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
        if(colorsLayout){
        setColorInsideButtons(colorsLayout.colorsLayout.colorInsideButtons);

        }
    }, []);

    const navigate = useNavigate();
    const[texEndereco, setTexEndereco ]= useState('');
    const  {authenticated, setAuthenticated} = useContext(authContext);
    const [dynamicMarginBottom, setDynamicMarginBottom] = useState(350);
    const[enderecoCopy, setEnderecoCopy ]= useState(false);
    const [colorInsideButtons , setColorInsideButtons] = useState();

    const marginbottomdynamic = () =>{

        if(window.screen.width === 1920 && window.screen.height === 1080){
            setDynamicMarginBottom(180);
        }else{
            setDynamicMarginBottom(77);
        }
    
    }

    const copiarTxt = () => {
        var copyText = document.getElementById("outlined-basic");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
      
         // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        setEnderecoCopy(true);

        setTimeout(()=>{
            setEnderecoCopy(false);
        },4000)
      
    }

    const buscarMeuEndereco = async () =>{
        const config = {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
            },
      
        };
    
        partnerAPI.post("/Partner/HashAddress", {
            cryptoUserID: sessionStorage.getItem("uid")
        },config).then(
            result => {
              setTexEndereco(result.data.hashWallet);
              console.log(result.data, 'buscarMeuEndereco');
            }
        ).catch(error =>{
            // console.log(error.response, 'buscarMeuEndereco');
            if (error.response.status === 401) {
              setAuthenticated(false);
            }
        })
    }

    return (

        <div>
            
            <div className='container' style={{margin: 10}}>
                <div className='row' style={{marginTop: 30}}>
                    <div className='col-sm-1 text-center'>
                        <InfoIcon/>
                    </div>
                    <div className='col-sm-11'>
                        <p>nome </p>
                    </div>
                </div>
                
                <div style={{marginLeft: 25}}>
                    <div style={{marginTop: 5,}}>
                        <div className='row'>
                            <div className='col-sm-6' style={{}}>
                                <TextField 
                                    inputProps={{readOnly: true}}
                                    className='col-sm-12' 
                                    id="outlined-basic" 
                                    value={texEndereco} 
                                    onChange={e => setTexEndereco(e.target.value)} 
                                    label="Meu Endereço ciPay" 
                                    variant="standard" 
                                />
                            </div>
                            <div className='col-sm-6' style={{padding: 10}}>
                                <div className='row'>
                                    <div className='col-sm-2'>
                                        <Button  endIcon={<ContentCopyIcon />} onClick={()=> copiarTxt()} 
                                         style={{
                                        backgroundColor: colorInsideButtons,
                                        }}>
                                            Copiar
                                        </Button>
                                    </div>
                                    <div className='col-sm-6' style={{margin: 5, paddingLeft: 20}}>
                                        {enderecoCopy &&
                                            <h5 style={{color: 'red'}}>Copiado !</h5>
                                        }
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-7' style={{marginTop: 10, marginBottom: dynamicMarginBottom} }>
                        <Card className='col-sm-12' style={{marginBottom: 25, paddingBottom: 150,}}>

                            <CardContent>
                                <div className='container'>

                                    <List sx={style} className='col-sm-12' component="nav" aria-label="mailbox folders">
                                        <ListItem className='col-sm-12' button onClick={()=> navigate('/check')}>
                                                <ListItemIcon>
                                                    <SettingsIcon />
                                                </ListItemIcon>
                                            <ListItemText primary="Verificação de conta" />
                                        </ListItem>
                                        <Divider className='col-sm-12' />
                                    </List>
                                </div>

                                
                            </CardContent>

                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}