import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Link,
  IconButton,
  FormControl,
  NativeSelect,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
// import ModalContaDeSaque from "./modalContaDeSaque";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  display: "flex",
  justifyContent: "center",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ModalSaque({ open, handleClose }) {
  useEffect(()=>{
        const colorsLayout = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
        if(colorsLayout){
        setColorInsideButtons(colorsLayout.colorsLayout.colorInsideButtons);

        }
  })
  const [valueSaque, setValueSaque] = useState();
  const[ colorInsideButtons , setColorInsideButtons] =useState();

  const handleInputChange = (event) => {
    const novoValor = event.target.value.replace(/[^0-9.,]/g, "");
    setValueSaque(novoValor);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box width="70%" display="flex" flexDirection="column" gap="10px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Selecione a moeda e o valor
            </Typography>
            <IconButton onClick={() => handleClose()}>X</IconButton>
          </Box>
          <InputLabel sx={{ fontWeight: 600 }} id="demo-simple-select-label">
            Moeda
          </InputLabel>
          <Select
            sx={{ width: "50%" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={1}
            label="Age"
          >
            <MenuItem value={1}>Real</MenuItem>
          </Select>
          <Typography mt={2} sx={{ fontWeight: 600 }}>
            Sacar com
          </Typography>
          <Box>
            <FormControlLabel
              checked
              disabled
              control={<Checkbox />}
              label="Pix (No momento só aceitamos saques com Pix)"
            />
          </Box>
          <Typography mt={2} sx={{ fontWeight: 600 }}>
            Valor do saque
          </Typography>
          <TextField
            label="Digite o valor em real"
            value={valueSaque}
            onChange={(e) => handleInputChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
          <Box display={"flex"} alignItems={"center"} gap={"5px"} mb={2}>
            <Typography>Número da conta:</Typography>
            {/* <Button display={"flex"} alignItems={"center"}>
                <ModalContaDeSaque />
                <KeyboardArrowDownIcon />
              </Button> */}
            <FormControl variant="standard">
              <Select defaultValue={1}>
                <MenuItem value={1}>71192929</MenuItem>
                <MenuItem value={2}>29328328</MenuItem>
                <MenuItem value={3}>92874673</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={() => handleClose()}
              sx={{ width: "45%" }}
              variant="contained"
              style={{
              backgroundColor: colorInsideButtons
            }}
            >
              Cancelar
            </Button>
            <Button sx={{ width: "45%" }} variant="contained" style={{
              backgroundColor: colorInsideButtons
            }}>
              Sacar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
