import axios from 'axios';

const pixApi = axios.create({
    //baseURL: 'https://apigatewaycipay.azure-api.net/corporate/',
    baseURL: 'https://tokenizor-api-hml-691f9cdec60d.herokuapp.com/',
    //baseURL:'https://localhost:44331/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials':true
    },
})

export default pixApi; 