import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import partnerAPI from "../../Services/partnerAPI";

import authContext from "../../Context/authContext";
import navContext from "../../../src/Context/navContext";
import firstLoginWhiteLabelContext from "../../../src/Context/firstLoginWhiteLabelContext";
import defaultAccessContext from "../../../src/Context/defaultAccessContext";
import accessLoginWhiteLabelContext from "../../../src/Context/accessLoginWhiteLabelContext";
import { ConfigLayoutContext } from "../../Context/configLayoutContext";  
import perfilContext from "../../../src/Context/perfilContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//material
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { parse } from "date-fns";


//import styles from "../login/login.css";

export default function Login() {
  const {configLayout, setConfigLayout} = useContext(ConfigLayoutContext)
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaInvalido, setSenhaInvalido] = useState(false);
  const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(0);
  const [dynamicPaddingTop, setDynamicPaddingTop] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visibleCreateAccount, setVisibleCreateAccount] = useState(false);

  const [imageLogo, setImageLogo] = useState("");

  const [searchParamsQueryString] = useSearchParams();
  const [queryStringToken] = useState(searchParamsQueryString.get("token"));
  
  const [loading, setLoading] = useState (true)
  const [colorInsideButtons, setColorInsideButtons] = useState();
  const [colorInsideNav, setColorInsideNav] = useState('');
  const [colorTextButton, setColorTextButton] = useState('');
  const [colorInsideFooter , setColorInsideFooter] = useState('');
  const [colorInsideDrawer , setColorInsideDrawer] = useState('');
  const [colorInsideLogo , setColorInsideLogo] = useState('');
  const [colorInsideButton , setColorInsideButton] = useState('');
  const [logoFront , setLogoFront] = useState('');
  const [logoFrontSquare , setLogoFrontSquare] = useState('');
  const [logoFooter , setLogoFooter] = useState('');

  const { authenticatedNav, setAuthenticatedNav } = useContext(navContext);
  const { authenticated, setAuthenticated } = useContext(authContext);
  const { isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel } = useContext(
    firstLoginWhiteLabelContext
  );
  const { authenticatedDefaultAccess, setAuthenticatedDefaultAccess } =
    useContext(defaultAccessContext);
  const { accessLoginWhiteLabel, setAccessLoginWhiteLabel } = useContext(
    accessLoginWhiteLabelContext
  );

  useEffect(() => {

    ajustarScreen();
    configurarLayoutLogin(queryStringToken);
    configColors();
  
    const savedColors = sessionStorage.getItem('colorsImageLayout');
  
    if (savedColors) {
      const parseColor = JSON.parse(savedColors);
      setColorInsideNav(parseColor.colorsLayout.colorInsideNav);
      setColorInsideFooter(parseColor.colorsLayout.colorInsideFooter);
      setColorInsideDrawer(parseColor.colorsLayout.colorInsideDrawer);
      setColorInsideButtons(parseColor.colorsLayout.colorInsideButtons);
      setConfigLayout(parseColor);
    }
  }, [queryStringToken]); 

  const { idPerfil, setIdPerfil } = useContext(perfilContext);

  const customNavigate = (route, param) => {
    let newRoute = route + "/?token=" + param;
    navigate(newRoute);
  };

  const ajustarScreen = () => {
    //console.log(window.screen.width, window.screen.height);
    if (window.screen.width === 1366 && window.screen.height === 768) {
      //calcularScreen(window.screen.width, window.screen.height)
      setDynamicPaddingBottom(120);
      setDynamicPaddingTop(1);
      return;
    } else {
      calcularScreen(window.screen.width, window.screen.height);
    }
  };

  const calcularScreen = (width, height) => {
    let ttBottom = (width * 100) / window.screen.width + 248;
    let ttTop = (height * 50) / window.screen.height + 20;
    // console.log(ttBottom, 'calcularScreen Bottom');
    // console.log(ttTop, 'calcularScreen Top');

    setDynamicPaddingBottom(ttBottom);
    setDynamicPaddingTop(ttTop);
  };

  const configurarLayoutLogin = (token) => {
    if (token !== null) {
      setVisibleCreateAccount(true);
    }

    //debugger;
      const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };

    partnerAPI.post("/Configure/LayoutLogin", {
        token: queryStringToken,
      },config).then((result) => {
        //console.log(result.data, ' result configurarLayoutLogin')
        setImageLogo(result.data.dsImageUrl);
        console.log(imageLogo,'logo')
        //sessionStorage.setItem("tkwl", result.data.token);
      })
      .catch((error) => {
        console.log(error.response, "error configurarLayoutLogin");
      });
  };

  const fazerLogin = () => {
    setVisible(true);

    setIdPerfil(10000);
      const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };

    if (login !== "" && senha !== "") {
      partnerAPI
        .post("/Login/Authentication", {
          email: login,
          password: senha,
          token: queryStringToken,
        },config)
        .then((result) => {
          if (result.data.msg === "no login exist") {
            setVisible(false);
            setSenhaInvalido(true);
            setTimeout(() => {
              setSenhaInvalido(false);
            }, 3000);
            return;
          }

          if (result.data.msg === "token white label not exist") {
            setVisible(false);
            setSenhaInvalido(true);
            setTimeout(() => {
              setSenhaInvalido(false);
            }, 3000);
            return;
          }

          if (result.data.msg === "no access on white label") {
            setVisible(false);
            setSenhaInvalido(true);
            setTimeout(() => {
              setSenhaInvalido(false);
            }, 3000);
            return;
          }

          if (result.data.msg === "no token into login") {
            setVisible(false);
            setSenhaInvalido(true);
            setTimeout(() => {
              setSenhaInvalido(false);
            }, 3000);
            return;
          }

          if (result.data.msg === "no access WhiteLabel by token") {
            setVisible(false);
            setSenhaInvalido(true);
            setTimeout(() => {
              setSenhaInvalido(false);
            }, 3000);
            return;
          }

          window.sessionStorage.setItem("token", result.data.content.token);
          window.sessionStorage.setItem(
            "uid",
            result.data.content.cryptoUserID
          );
          window.sessionStorage.setItem("contract", queryStringToken);
          console.log(result, "partnerAPI /Login/Authentication");

          //console.log(result.data.content.token, 'result.data.content.token');
          //console.log(result.data.content.cryptoUserID, 'result.data.content.token');
          setIsFirstLoginWhiteLabel(false);
          setAuthenticatedNav(true);

          //create token
          if (result.data.content.typeAcess === 1) {
            sessionStorage.setItem("email", login);
            setIsFirstLoginWhiteLabel(true);
            setAuthenticatedNav(false);
          }

          //mother wallet
          if (result.data.content.typeAcess === 2) {
            console.log("entrou  //mother wallet");
            setAccessLoginWhiteLabel(2);
            setAuthenticatedDefaultAccess(true);
          }

          setVisible(false);
          setAuthenticated(true);
          navigate("/home");
        })
        .catch((error) => {
          console.log(error, " error /Login/Authentication");
          setVisible(false);
        });
    } else {
      if (login !== "" && senha !== "") {
        setSenhaInvalido(true);
      } else {
        setVisible(false);
        toast.warning("Insira E-mail e senha !");
      }
    }
  };

  const configColors  = async () => {
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
    };
    
    await partnerAPI.post("/Configure/LayoutPartner",{ 
      dsTokenPartnerConfigure: queryStringToken
    }, config).then(
    result=>{
      if(result.data){
        setLoading(true) 
        debugger;
        const logoColor = result.data[0]?.nmColor|| "#DAAD4A" ;
        const buttonColor = result.data[1]?.nmColor || "#D4AA51";       
        const navColor = result.data[2]?.nmColor || "#D4AA51";
        const drawerColor = result.data[3]?.nmColor ||"#D4AA51";
        const footerColor = result.data[4]?.nmColor|| "#191919";
        const buttonsColor = result.data[5]?.nmColor|| "#D4AA51";
        const urlLogo = result.data[0]?.dsImageUrl || "/Assets/img/CI_PAY_2corporate.png" ;
        const urlLogoSquare = result.data[1]?.dsImageUrl || "/Assets/img/CI_PAY_corporate.png";
        const urlFooter = result.data[4]?.dsImageUrl || "/Assets/img/ci_3d_2_180px.png" ;
        const nameNav = result.data[4]?.nameNav || "CIPay" ;
        const colorTextButton = result.data[4]?.colorTextButton || "#242424" ;
          
          setColorInsideLogo(logoColor);
          setColorInsideButton(buttonColor);
          setColorInsideNav(navColor);
          setColorInsideDrawer(drawerColor);
          setColorInsideFooter(footerColor);
          setColorInsideButtons(buttonsColor);
          setLogoFront(urlLogo);
          setLogoFrontSquare(urlLogoSquare);
          setLogoFooter(urlFooter);
          setColorTextButton(colorTextButton);
          
          console.log(result.data, 'imagem')
          const colorsAndImageFront = {
            NameNav:"C",
            colorsLayout:{            
              colorInsideLogo:logoColor,
              colorInsideButton:buttonColor,
              colorInsideNav: navColor,
              colorInsideFooter:  footerColor,
              colorInsideDrawer: drawerColor,
              colorInsideButtons: buttonsColor, 
            },
            imagesLayout:{
              logoFront:urlLogo,
              logoFrontSquare:urlLogoSquare,
              logoFooter: urlFooter,            
            }
          };
          setConfigLayout(colorsAndImageFront)
          sessionStorage.setItem('colorsImageLayout', JSON.stringify(colorsAndImageFront));
          //console.log(colorsDefault, 'coress na session')      


      }else{
        if(queryStringToken === null){
          console.log(queryStringToken , 'token')
          setColorInsideLogo("#DAAD4A");
          setColorInsideButton("#FF5616"); 
          setColorInsideNav("#D4AA51");
          setColorInsideFooter("#191919");
          setColorInsideDrawer("#D4AA51");
          setColorInsideButtons("#FF5616");
          setLogoFront("/Assets/img/CI_PAY_2corporate.png");
          setLogoFrontSquare("/Assets/img/CI_PAY_corporate.png");
          setLogoFooter("/Assets/img/ci_3d_2_180px.png");
        }
      }
      
      setLoading(false)
      
    }
    ).catch ( 
      error =>{  
        console.log(error, 'deu erro')  
    })  
  };
    

  return (
    <div 
      className="login "
      onKeyDown={(event) => {
        if (event.key === "Enter") fazerLogin();
      }}
    >
      {loading ?(<>
        <div  className="d-flex  align-items-center justify-content-center  " style={{ height:'100vh',backgroundColor:"lightgrey",  }}>
          <Card style={{ height:'200px', width:'200px', borderRadius:'25px' }} >
            <Stack sx={{ color: 'grey.500', alignItems:'center', paddingTop:'35%' }} className='text-center' spacing={2}    >
              <CircularProgress size={50} style={{}}  />        
            </Stack>
          </Card>
        </div>
      </>):(
      <div className="d-flex  align-items-center col-sm-12 " style={{ height:'100vh',  }}>
        <Card className=" logo col-sm-9 d-flex justify-content-center align-items-center" style={{ backgroundColor:colorInsideLogo, background: `radial-gradient(circle, #fffffff4 10%, ${colorInsideLogo} 70%)` }}sx={{height:'100vh' }}>
          <CardContent>
            <div className="  d-flex justify-content-center" style={{ alignItems:"center",margin:"center"}}>
              <img src={'/Assets/img/CI_PAY_2corporate.png'} className="col-sm-4   "  alt=""  />
            </div>
          </CardContent>
        </Card>
        <Card className=" justify-content-center align-items-center col-sm-3"  sx={{ backgroundColor:"lightgrey", height:'100vh'}}>
          <CardContent style={{marginTop: '50%'}}>
            <div className="col-sm-12 d-flex justify-content-center align-items-center" >
              <img src={logoFrontSquare} alt="" style={{marginBottom:10, width:130 }} />
            </div>

            <div
              onKeyDown={(event) => {
                if (event.key === "Enter") fazerLogin();
              }}>
              {!visible && (
                <>
                  <div style={{marginBottom:15 , backgroundColor:"#f3f3f3" }}>
                    <TextField                      
                      className="col-sm-12"
                      label="E-mail"
                      value={login}
                      variant="filled"
                      onChange={(e) => setLogin(e.target.value)}                      
                    />
                  </div>
                  <div style={{ backgroundColor:"#f3f3f3", }} >
                    <TextField
                      className="col-sm-12"
                      label="Senha"
                      type="password"
                      value={senha}
                      variant="filled"
                      onChange={(e) => setSenha(e.target.value)}
                     
                    />
                  </div>
                  {visibleCreateAccount && (
                    <div
                      onClick={() =>
                        customNavigate("/cadastro-conta", queryStringToken)
                      }
                      style={{
                        margin: 5,
                        textDecoration: "underline",
                        textDecorationThickness: 1,
                        color: "#9B35AF",
                      }}
                    >
                      <a>
                        <b>Abra sua Conta</b>
                      </a>
                    </div>
                  )}
                </>
              )}

              {!visible && (
                <div className="my-3">
                  <Button
                    className="col-sm-12"
                    style={{
                      backgroundColor: colorInsideButton,
                      color: colorTextButton,
                      borderColor: colorInsideButton
                    }}
                    variant="outlined"
                    onClick={() => fazerLogin()}
                  >
                    Continuar
                  </Button>
                </div>
              )}

              {visible && (
                <div className="my-4">                
                  <div style={{ paddingLeft: 140, margin: 8 }}>
                    <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                      <CircularProgress color="secondary" />
                    </Stack>
                  </div>
                </div>
              )}

              {senhaInvalido && (
                <div>
                  <h6 style={{ color: "red" }}>Usuario ou senha inválidos</h6>
                </div>
              )}

              {!visible && (
                <div className="d-flex col-sm-12 d-flex justify-content-center align-items-center" >               
                  <div>
                    <a href="https://www.linkedin.com/company/cipay-blockchain/" target="_blank" rel="noreferrer" ><LinkedInIcon fontSize="large"  /></a>
                  </div>
                    <a className="instagram" href="https://www.instagram.com/cipayblockchain/" target="blank"><InstagramIcon   style={{backgroundColor:" #7d4fdd",backgroundImage: "linear-gradient(210deg, #7d4fdd 0%, #ff6272 50%, #edc11c 100%)", color:"white", borderRadius:5, fontSize:27  }}/></a>
                </div>)
              }
              
            </div>
          </CardContent>
        </Card>
      </div>
      )}

      <ToastContainer />
    </div>
  );
}
