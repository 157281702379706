import React, {useContext, useState, useEffect} from 'react'
import { useNavigate ,BrowserRouter as Router} from 'react-router-dom';
import partnerAPI from '../../../Services/partnerAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//material
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Button from '@mui/material/Button';
import { fontSize } from '@mui/system';

export default function GarantiaContrato(props){
    useEffect(() => { 
    const colorsLayout = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
    if(colorsLayout){
    setColorInsideButtons(colorsLayout.colorsLayout.colorInsideButtons);

  }
  }, []);
  const [colorInsideButtons , setColorInsideButtons] =useState ();
    const [btnAlter, setBtnAlter] = useState(false)
    const [btnSave, setBtnSave] = useState(false)
    const [isSaveBlockchain, setIsSaveBlockchain] = useState(false)

    const [garantia, setGarantia] = React.useState('');
    const [tipoGarantia, setTipoGarantia] = React.useState('');

    useEffect(() => {
        //locateGuarantee();
        //localizarSaldoCrypto();
    },[]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState('teste');

    const onFileChange = (event) => {
        debugger;
        if(event !== undefined){
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleChangeGarantia = (event) => {
        setGarantia(event.target.value);
    };

    const handleChangeTipoGarantia = (event) => {
        setTipoGarantia(event.target.value);
    };

    const onFileUpload = () => {
        debugger;
        if(garantia === ""){
            toast.warning('Selecione a Garantia');
            return;
        }

        if(tipoGarantia === ""){
            toast.warning('Selecione a Tipo Garantia');
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        let typeGuarantee = tipoGarantia;
        let addressContract = sessionStorage.getItem("contract");
        const url = "/Partner/Upload/Guarantee/Token?addressContract="+addressContract+"&"+"typeGuarantee="+typeGuarantee+""

        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };
        partnerAPI.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        },config).then((res) => {
            console.log(res.data, '/Partner/Upload/Guarantee/Token');
        }).catch(error => {
            console.log(error.response, 'catch /Partner/Upload/Guarantee/Token');
        });
    };

    const locateGuarantee = () => {
        const config = {
            headers: { 
                Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };

        partnerAPI.post("/Partner/Upload/Guarantee/Token", {
            addressContract: "",
            typeGuarantee: ""
        },config).then((res) => {
            console.log(res.data, '/Asset/Locate/Guarantee');
        }).catch(error => {
            console.log(error.response, 'catch /Partner/Upload/Guarantee/Token');
        });
    }
    


    return (
        <div className='row'>
            <div className='col-sm-5' style={{marginLeft: 25}}>
                <div className='container my-5 card' >
                
                <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                    <h5>Garantias de Contrato</h5>
                </div>

                <div className='container' style={{marginBottom: 10}}>
                    <div className='row border border-2' style={{marginBottom: 15, borderRadius: 20, paddingBottom: 150}}>
                        <div className='col-sm-12 my-3'>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Garantia</InputLabel>
                                    <Select style={{width: 200}}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={garantia}
                                    onChange={handleChangeGarantia}
                                    label="Garantia"
                                    >
                                        <MenuItem value={"Real"}>Real</MenuItem>
                                        <MenuItem value={"Real"}>Contrato</MenuItem>

                                    </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id='label2'>Tipo de Garantia</InputLabel>
                                    <Select style={{width: 200}}
                                    labelId="label2"
                                    id="demo-simple-select-standard"
                                    value={tipoGarantia}
                                    onChange={handleChangeTipoGarantia}
                                    label="Tipo de Garantia"
                                    >
                                        <MenuItem value={0}></MenuItem>
                                        <MenuItem value={"Imovel"}>Imovel</MenuItem>
                                        <MenuItem value={"Terreno"}>Terreno</MenuItem>
                                        <MenuItem value={"Precatorio"}>Precatório</MenuItem>
                                    </Select>
                            </FormControl>

                            <div className='col-sm-5' style={{marginTop: 10}}>
                                <CardActionArea>                      
                                    <Paper className='col-sm-12' elevation={10} sx={{paddingTop: 2 ,paddingBottom: 2, width: 420}} onClick={()=> onFileChange()}>
                                        <div style={{paddingLeft: 10}}>
                                            <div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-10'>
                                                <input type="file" enctype="multipart/form-data" elevation={10} sx={{paddingTop: 2 ,paddingBottom: 2}} onChange={onFileChange} />
                                                    <div></div>
                                                </div>
                                                {/* <div className='col-sm-2'>
                                                    <DriveFolderUploadIcon/>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Paper>
                                    
                                </CardActionArea>


                                    
                            </div>
                            <div style={{margin: 10}}>
                                <div className='row'>
                                    <div className='col-sm-8' >
                                        
                                    </div>
                                    <div className='col-sm-4'>
                                        {btnAlter &&
                                            <Button style={{backgroundColor: colorInsideButtons}} variant="contained">Alterar</Button>
                                        }
                                        <Button style={{backgroundColor: colorInsideButtons}} variant="contained" onClick={onFileUpload}>Salvar</Button>
                                    </div>
                                </div>
                            </div>

                            { isSaveBlockchain ?    <div>
                                    <h3>Contrato Anexado em Blockchain</h3>
                                    <div>
                                        Contrato: arquivo
                                    </div>
                                </div> : "" 
                            }
                        </div>

                        
                    </div>
                </div>
                <ToastContainer />
                </div>
            </div>

            <div className='col-sm-6'></div>
        </div>

    );
}