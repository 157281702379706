import React , { useState, useEffect} from "react" 
import bankAPI from "../../../Services/bankAPI";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

//material
import Tabela from '../../components/Tabela/tabela'
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
//import CardActionArea from "@mui/material/CardActionArea";
import { Button } from "@mui/material";
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";  


    // colunas 
    const columns = [
    { field: 'id', headerName: '', width: 0},
    { field: 'avatar', headerName: '', width: 70, renderCell:(params) =>
        (<Avatar
            src={params.value} 
            alt='Moeda'
            style={{ width: 30, height: 30, marginTop: 8}}
            />
        ) },    
    { field: 'nmStaking', headerName:'Nome do Staking', width:150}, 
    { field: 'cdCryptoWalletSaldo', headerName: 'Valor Aportado', width: 150 },
    { field: 'cdCrypto', headerName: 'Rentabilidade', width:150},
    { field: 'amountCreate', headerName: 'Quant Aportado', width: 150 },
    { field: 'dtInsert', headerName:'Data do Aporte', width:200}    
    ];

export default function Stacking() {
    const navigate = useNavigate();
    const [loading , setLoading] = useState (true);
    const [dados , setDados] = useState([]);
    const [rows, setRows] =useState([]);
    const [dadosStaking, setDadosStaking]= useState ();
    const [value, setValue] = useState(1);
    const[ colorInsideButtons , setColorInsideButtons] =useState();
 

    useEffect(()=>{
       // dadosStacking();
        //dadosAportes(); 
         const colorsLayout = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
        if(colorsLayout){
        setColorInsideButtons(colorsLayout.colorsLayout.colorInsideButtons);

  }         
    }, []);

    //pega os dados do staking
    // const dadosStacking= async () => {
    //     const config = {
    //         headers: {
    //             Authorization: "Bearer " + (await sessionStorage.getItem("token")),
    //         },
    //     };
    //     bankAPI.post("/Staking/GetStakingProducts",{}, config )
    //     .then((result)=>{  
    //         const staking= result.data.stakings[0]
             
        
    //         if (staking.amountCreate === 0){
    //             setDadosStaking(staking.stakingRentability)
    //             console.log(dadosStacking)
    //         } else if (staking.stakingRentability ===0 ){
    //             setDadosStaking(staking.amountCreate)
    //         }        
    //         setDados(result.data.stakings)
    //         setLoading(false)
    //        console.log(result.data.stakings, 'dados dos Stakings  com sucesso')         
    //     })
    //     .catch((erro)=>{
    //         console.log(erro,'erro ao puxar os dados')            
    //     })
    // };
       //pega os dados do aporte 
    // const dadosAportes =  () =>{
    //     const config = {
    //         headers: {
    //         Authorization: "Bearer " +  sessionStorage.getItem("token"),
    //     },
    // };
    //     bankAPI.post('/Staking/GetUserStaking',{cdCryptoUserId: sessionStorage.getItem("uid")}, config)
    //     .then((result)=>{
    //        console.log(result,'dados Aporte com sucesso')
    //             //debugger;  
    //             const aporteData = Array.isArray(result.data.staking) ?  result.data.staking : [];                      
    //             const linhas  = aporteData.data.staking.map((item, index) => ({
    //             id : index + 1,
    //             avatar: item.urlImgCrypto,  
    //             nmStaking:item.nmStaking,
    //             cdCryptoWalletSaldo: item.cdCryptoWalletSaldo,
    //             cdCrypto: item.cdCrypto,
    //             amountCreate: item.amountCreate,
    //             dtInsert:format(new Date (item.dtInsert), 'dd/MM/yyyy HH:mm:ss')  ,
                    
    //             }))
    //             setRows(linhas)
    //             console.log(rows, 'deu bom')    
                
    //     })
    //     .catch((erro)=>{
    //         console.log(erro,'Erro ao puxar os dados do Aporte')
    //     })
    // }


    // function da tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //config do slider 
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,    
        slidesToScroll: 1,
    }

    return(
        <>        
             <div className="mt-5 pb-5 p-2 "> 
                 <div className="mb-5" >
                     {loading ?(
                        <Card className="col-sm-4 mb-5 "   style={{ height:250, borderRadius: 35, padding:15 , width:435 , marginLeft:50}} >
                            <CardContent>
                                <div className="d-flex justify-content-between alig-item-center m-2 p-1">
                                    <Skeleton variant="rectangular" width={250} height={30} />
                                    <Skeleton variant="circular" width={50} height={50} />
                                </div>
                                    <Skeleton variant="rectangular"  width={180} height={110} />                                 
                            </CardContent>
                        </Card>                        
                        ):(
                               
                        dados.map((item , index)=>(
                            <div key={index} className='slider-container  col-sm-11 ' style={{margin:'auto'}}>
                                <Slider {...settings}   >
                                <div >                                     
                                    <div style={{ height:250, borderRadius: 35, padding:15 , width:405 , marginRight:35, backgroundColor:'white'}}>
                                        <CardContent >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1" >
                                                <h5 className="mt-2"> {item.nmStaking}</h5>
                                                <Avatar alt="Remy Sharp" style={{ width: 45, height: 45}} src={item.urlImgCrypto}/>
                                            </div>
                                            <div className="col-sm-12 row p-1">
                                                <div className="col-sm-6 me-3" style={{border:'solid 2px lightgray',borderRadius:15, boxShado:'1px 1px 1px black' }} >
                                                    <CardContent>
                                                        <h6>
                                                            <div className="mb-1">
                                                                Arrecadação Máxima:
                                                            </div>
                                                            
                                                                {item.stakingRentability <= 100000 ? item.stakingRentability : format(new Date (item.dtInsert),"dd/MM/yyyy")}
                                                        </h6>
                                                    </CardContent>
                                                </div>
                                                <div className="col-sm-6 row">
                                                    <Link onClick={()=>navigate("/saibaMaisStacking")} className="ms-4 d-flex flex-row-reverse align-items-end" style={{textDecorationLine:'none',fontSize:14}}>
                                                     saiba mais
                                                    </Link>
                                                    <div>
                                                        <Button variant="contained" color='secondary'className="col-sm-9 p-2" style={{height:40, width:150}} >Aportar</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>                                    
                                </div>

                                <div >                                     
                                    <div   style={{ height:250, borderRadius: 35, padding:15 , width:405 , marginRight:35, backgroundColor:'white'}}>
                                        <CardContent >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1" >
                                                <h5 className="mt-2"> {item.nmStaking}</h5>
                                                <Avatar alt="Remy Sharp" style={{ width: 45, height: 45}} src={item.urlImgCrypto}/>
                                                </div>
                                            <div className="col-sm-12 row p-1">
                                                <Card className="col-sm-6 me-3" style={{border:'inset 2px',borderRadius:15, boxShado:'1px 1px 1px black' }} >
                                                    <CardContent>
                                                        <h6>
                                                            <div className="mb-1">
                                                                Arrecadação Máxima:
                                                            </div>
                                                            <br/>
                                                                {dadosStaking}
                                                        </h6>
                                                    </CardContent>
                                                </Card>
                                                <div className="col-sm-6 row">
                                                    <Link onClick={()=>navigate("/saibaMaisStacking")} className="ms-4 d-flex flex-row-reverse align-items-end" style={{textDecorationLine:'none',fontSize:14}}>
                                                     saiba mais
                                                    </Link>
                                                    <div>
                                                        <Button variant="contained" color='secondary'className="col-sm-9 p-2" style={{ backgroundColor: colorInsideButtons ,height:40, width:150}} >Aportar</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>                                    
                                </div>

                                <div >                                     
                                    <div   style={{ height:250, borderRadius: 35, padding:15 , width:405 , marginRight:35, backgroundColor:'white'}}>
                                        <CardContent >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1" >
                                                <h5 className="mt-2"> {item.nmStaking}</h5>
                                                <Avatar alt="Remy Sharp" style={{ width: 45, height: 45}} src={item.urlImgCrypto}/>
                                                </div>
                                            <div className="col-sm-12 row p-1">
                                                <div className="col-sm-6 me-3" style={{border:'solid 1px lightgray',borderRadius:15, boxShado:'1px 1px 1px black' }} >
                                                    <CardContent>
                                                        <h6>
                                                            <div className="mb-1">
                                                                Arrecadação Máxima:
                                                            </div>
                                                            <br/>
                                                                {dadosStaking}
                                                        </h6>
                                                    </CardContent>
                                                </div>
                                                <div className="col-sm-6 row">
                                                    <Link onClick={()=>navigate("/saibaMaisStacking")} className="ms-4 d-flex flex-row-reverse align-items-end" style={{textDecorationLine:'none',fontSize:14}}>
                                                     saiba mais
                                                    </Link>
                                                    <div>
                                                        <Button variant="contained" color='secondary'className="col-sm-9 p-2" style={{height:40, width:150}} >Aportar</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>                                    
                                </div>
                            
                                <div >                                     
                                    <div   style={{ height:250, borderRadius: 35, padding:15 , width:405 , marginRight:35, backgroundColor:'white'}}>
                                        <CardContent >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1" >
                                                <h5 className="mt-2"> {item.nmStaking}</h5>
                                                <Avatar alt="Remy Sharp" style={{ width: 45, height: 45}} src={item.urlImgCrypto}/>
                                                </div>
                                            <div className="col-sm-12 row p-1">
                                                <Card className="col-sm-6 me-3" style={{border:'inset 2px',borderRadius:15, boxShado:'1px 1px 1px black' }} >
                                                    <CardContent>
                                                        <h6>
                                                            <div className="mb-1">
                                                                Arrecadação Máxima:
                                                            </div>
                                                            <br/>
                                                                {dadosStaking}
                                                        </h6>
                                                    </CardContent>
                                                </Card>
                                                <div className="col-sm-6 row">
                                                    <Link onClick={()=>navigate("/saibaMaisStacking")} className="ms-4 d-flex flex-row-reverse align-items-end" style={{textDecorationLine:'none',fontSize:14}}>
                                                     saiba mais
                                                    </Link>
                                                    <div>
                                                        <Button variant="contained" color='secondary'className="col-sm-9 p-2" style={{height:40, width:150}} >Aportar</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>                                    
                                </div>

                                <div >                                     
                                    <div   style={{ height:250, borderRadius: 35, padding:15 , width:405 , marginRight:35, backgroundColor:'white'}}>
                                        <CardContent >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1" >
                                                <h5 className="mt-2"> {item.nmStaking}</h5>
                                                <Avatar alt="Remy Sharp" style={{ width: 45, height: 45}} src={item.urlImgCrypto}/>
                                                </div>
                                            <div className="col-sm-12 row p-1">
                                                <Card className="col-sm-6 me-3" style={{border:'0.2px solid black',borderRadius:15, boxShado:'1px 1px 1px black' }} >
                                                    <CardContent>
                                                        <h6>
                                                            <div className="mb-1">
                                                                Arrecadação Máxima:
                                                            </div>
                                                            <br/>
                                                                {dadosStaking}
                                                        </h6>
                                                    </CardContent>
                                                </Card>
                                                <div className="col-sm-6 row">
                                                    <Link onClick={()=>navigate("/saibaMaisStacking")} className="ms-4 d-flex flex-row-reverse align-items-end" style={{textDecorationLine:'none',fontSize:14}}>
                                                     saiba mais
                                                    </Link>
                                                    <div>
                                                        <Button variant="contained" color='secondary'className="col-sm-9 p-2" style={{height:40, width:150}} >Aportar</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>                                    
                                </div>
                                </Slider>
                            </div>
                        ))                          
                    )}       
                </div>
                <div className=" col-sm-9 ms-4"> 
                 <Box sx={{  typography: 'body1' }}>                   
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} textColor='secondary'  indicatorColor="secondary"  aria-label="lab API tabs example" >
                            <Tab label="Meus Aportes" value={1} className="ms-4" />
                            <Tab label="Novos Stackings" value={2} />
                          
                        </TabList>
                        </Box>
                        <TabPanel value={1}>
                        <Tabela
                            data={rows}
                            columns={columns}
                        />
                        </TabPanel>
                        <TabPanel value={2}>Novos Stackings</TabPanel>
  
                    </TabContext>            
                    </Box>   
                </div>    
            </div> 
        </>
    )
}

 