import React, { useEffect, useState } from "react";
import { useNavigate, BrowserRouter as Router } from "react-router-dom";
import ListSmartContract from "./components/listSmartContract";
import ListCashBack from "./components/listCashBack";
import partnerAPI from "../../Services/partnerAPI";

export default function ConfiguracoesToken(props) {
  useEffect(() => {
    carregarListaServicos();
  },[] );
  const navigate = useNavigate();
  const [produto, setProduto] = useState(9);

  const [dynamicBottom, setDynamicBottom] = useState(200);

  const carregarListaServicos = async () => {
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };  
    
    const plano  = await partnerAPI.post("/Partner/Plan",{
      cryptoUserID: window.sessionStorage.getItem("uid")          
    },config).then(
      result => {
        setDynamicBottom(0);
        return result.data.content  
    }).catch(error=>{
      console.log(error.response, "Partner Plan Error")
    });
    console.log(plano, "Plano")
    
    if (plano === null){
      setProduto(0)
    } 
    else if(plano.nmPlan === "Free Tier" ){
      if(plano.nmProduct ===  "Cashback"){
        setProduto(1)
      }
    }//
  }

  return (
    <div className="ms-4" style={{padding: dynamicBottom}}>
      <div style={{ marginTop: 10 }}>
        {produto === 0 && <ListSmartContract />}
        {produto === 1 && <ListCashBack />}
      </div>
    </div>
  );
}
