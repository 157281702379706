import { createContext, useContext, useState } from "react";

const DrawerContext = createContext();

export const DrawerProvider = ({children}) => {
    const [navHeight, setNavHeight] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [drawerWidth, setDrawerWidth] = useState(180);
    const [configProducts, setConfigProducts] = useState({})

    return (
        <DrawerContext.Provider 
        value={{
            navHeight, setNavHeight, 
            drawerOpen, setDrawerOpen, 
            drawerWidth, setDrawerWidth,
            configProducts, setConfigProducts
        }}>
            {children}
        </DrawerContext.Provider>
    )
}

export const useDrawerContext = () => useContext(DrawerContext);