import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState, useContext} from 'react';

import { BrowserRouter as Router} from 'react-router-dom';

import MainRoutes from './route/routes';
import Nav from './pages/components/Nav/nav';
import Footer from './pages/components/Footer/footer';
import authContext from './Context/authContext';
import navContext from './Context/navContext';
import firstLoginWhiteLabelContext from './Context/firstLoginWhiteLabelContext';
import defaultAccessContext from './Context/defaultAccessContext';
import accessLoginWhiteLabelContext from './Context/accessLoginWhiteLabelContext';
import perfilContext from './Context/perfilContext';
import NavDrawer from './pages/components/NavDrawer/navDrawer';
import { ConfigLayoutProvider } from './Context/configLayoutContext';

import { DrawerProvider, useDrawerContext } from "../src/Context/drawerContext";
const AppContent = () => {
  const { drawerOpen, drawerWidth } = useDrawerContext();
  const { authenticated } = useContext(authContext);

  return (
    <div style={{
      marginLeft: authenticated ? (drawerOpen ? drawerWidth : drawerWidth ) : 0,      
      transition: 'margin-left 0.3s',
      
      
    }}>
      <MainRoutes authenticated={authenticated} />
      
    </div>
  );
};

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticatedNav, setAuthenticatedNav] = useState(false);
  const [isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel] = useState(false);
  const [authenticatedDefaultAccess, setAuthenticatedDefaultAccess] = useState(false);
  const [accessLoginWhiteLabel, setAccessLoginWhiteLabel] = useState(0);
  const [idPerfil, setIdPerfil] = useState(0);

  return (
    <>
        <DrawerProvider>
        <ConfigLayoutProvider>        
            <authContext.Provider value={{authenticated, setAuthenticated}}>
            <navContext.Provider value={{authenticatedNav, setAuthenticatedNav}}>
            <firstLoginWhiteLabelContext.Provider value={{isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel}}>
            <defaultAccessContext.Provider value={{authenticatedDefaultAccess, setAuthenticatedDefaultAccess}}>
            <accessLoginWhiteLabelContext.Provider value={{accessLoginWhiteLabel, setAccessLoginWhiteLabel}}>
            <perfilContext.Provider value={{idPerfil, setIdPerfil}}>
                <Router>
                  {
                    authenticated &&
                    <NavDrawer />
                  }
                    <AppContent/>
                  {
                    authenticated &&
                    
                    <Footer />                    
                  }
                  
                </Router>
            </perfilContext.Provider>
            </accessLoginWhiteLabelContext.Provider>
            </defaultAccessContext.Provider>
            </firstLoginWhiteLabelContext.Provider>
            </navContext.Provider>
            </authContext.Provider>
        </ConfigLayoutProvider>
        </DrawerProvider>

    </> 
  );
}

export default App;
