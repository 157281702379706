import React, {useState , useEffect, useContext} from 'react'
import { ConfigLayoutContext } from '../../../Context/configLayoutContext';
import '../Footer/footer.styles.css'
 

 

export default function Footer(){    
    const { configLayout } = useContext(ConfigLayoutContext); 
            useEffect(() => {
        //debugger
        configColors();
        
    },[configLayout]);
     
    const [colorInsideFooter , setColorInsideFooter] = useState('');
    const [logoFooter , setLogoFooter] = useState("");


    const configColors =  () => {
            console.log(configLayout , ' teste')
            if (configLayout) {
            setColorInsideFooter(configLayout.colorsLayout.colorInsideFooter);
            setLogoFooter(configLayout.imagesLayout.logoFooter)
        }
    };

    return ( 
            
        <footer className="footer-clean"style={{
                backgroundColor:colorInsideFooter,
            }}>
            <div className="row" >
                <div>   
                    <img src={logoFooter} alt="Logo do Rodapé" style={{width: "3%"}} />
                </div>
            </div>
        </footer> 

    );
} 