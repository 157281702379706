import React, {useContext, useState, useEffect} from 'react'

import { useNavigate ,BrowserRouter as Router} from 'react-router-dom';


//material
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import partnerAPI from '../../../Services/partnerAPI';


export default  function  TaxaTransacao () {
        useEffect(() => { 
    const colorsLayout = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
    if(colorsLayout){
    setColorInsideButtons(colorsLayout.colorsLayout.colorInsideButtons);

  }
  }, []);

      const [colorInsideButtons , setColorInsideButtons] =useState ();
          const [precoInauguracao, setPrecoInauguracao] = useState();
    const [precoFixo, setPrecoFixo] = useState();

        const handleClickSalvar = () => {
                
        // if(txtRetability === null || txtRetability <= 0){
        //     return window.alert('O campo Rentabilidade deve ser maior que zero');
        // }
        // if(txtClosingMonth === null || txtClosingMonth <= 0){
        //     return window.alert('O campo Meses Fechamento deve ser maior que zero');
        // }

        // let date = new Date(txDatePayments);         
        // if(!isNaN(date) === false){
        //     return window.alert('O campo Data Inicio deve ser preenchido');
        // }

        // var contract = sessionStorage.getItem('contract');        
        // if(contract === null || contract === "" || contract === "null"){
        //     return window.alert('Acesso não permitido para fazer determinada ação.');
        // }

        // var data = {
        //     'Rentability': txtRetability,
        //     'ClosingMonth': txtClosingMonth,
        //     'DatePayments': txDatePayments,
        //     'Ipca': boolIPCA,
        //     'ContractHash': contract
        // };
        
        // // agora fica para fazer a requisição para o back-end 
        // const config = {
        //     headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
        //     },
        // };       

        // partnerAPI.post("Partner/Debenture_Update", data,config)
        // .then((res) => {
        //     console.log(res.data, 'SUCCESS');

        //     if(res.data.success === false){
        //         let text = "";
        //         for(let prop in res.data.erros){
        //             text += `${prop} : ${res.data.erros[prop]}` + "\n";
        //         }                
        //         window.alert(text);                                                 
        //     }else{
        //         window.alert('Fluxo processado com sucesso!');                                
        //     }
        // })            
        // .catch(error => {
        //     window.alert('Ocorreu algum erro no processamento!');                
        //     console.log(error.response, 'ERRO');
        // });

      };

          const handleInputChangeI = (event) => {
    const novoValor = formatCurrency(event.target.value.replace(/[^0-9.,]/g, ''));
    setPrecoInauguracao(novoValor);
   
  };

    const handleInputChangeF = (event) => {
    const novoValorF = formatCurrency(event.target.value.replace(/[^0-9.,]/g, ''));    
    setPrecoFixo(novoValorF);
  };

    const formatCurrency = (value) => {
    // Remove caracteres não numéricos da string
    const numericValue = value.replace(/\D/g, '');

    // Adiciona o símbolo R$ e formata a string com a máscara desejada
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
        if (i === numericValue.length - 2) {
            formattedValue += '.';
        }
        formattedValue += numericValue[i];
    }

    // Formata o número como moeda brasileira
    return Number(formattedValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }


    return (
        <div className='row'>
            <div className='col-sm-5' style={{marginLeft: 25}}>
                <div className='container my-5 card'>
                
                <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                    <h5>Taxa de Transação</h5>
                </div>

                <div className='container'>
                    <div className='row border border-2' style={{marginBottom: 15, borderRadius: 20}}>
                        <div className=''>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12'                                    
                                    label="Valor em Reais por Transação" 
                                    variant="standard" 
                                    value={precoInauguracao} 
                                    onChange={(e) => handleInputChangeI(e)} 
                                />
                            </div>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12'                                    
                                    label="Porcentual do Token por Transação" 
                                    variant="standard" 
                                    value={precoFixo} 
                                    onChange={(e) => handleInputChangeF(e)} 
                                />
                            </div>
                        </div>                      
                        <div style={{position:"relative"}}>
                            <div style={{float:"right", margin:"10px"}}>                                                        
                                <Button 
                                    style ={{backgroundColor: colorInsideButtons}}
                                    variant="contained"
                                    onClick={handleClickSalvar}
                                >
                                Salvar
                                </Button>                            
                            </div> 
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-sm-6'></div>
        </div>
    )
}