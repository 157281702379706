import React, {useContext, useState} from 'react'
import { useNavigate ,BrowserRouter as Router} from 'react-router-dom';


//material
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import partnerAPI from '../../../Services/partnerAPI';


export default function DistribuicaoToken(props){

    const [txtRetability, setTxtRetability] = useState('')
    const [txtClosingMonth, setTxtClosingMonth] = useState('')
    const [txDatePayments, setTxtDatePayments] = useState(new Date);
    const [boolIPCA, setBoolIPCA] = useState(false)    

    const label = { inputProps: { 'aria-label': 'Size switch demo' } };

    const handleClickSalvar = () => {
                
        if(txtRetability === null || txtRetability <= 0){
            return window.alert('O campo Rentabilidade deve ser maior que zero');
        }
        if(txtClosingMonth === null || txtClosingMonth <= 0){
            return window.alert('O campo Meses Fechamento deve ser maior que zero');
        }

        let date = new Date(txDatePayments);         
        if(!isNaN(date) === false){
            return window.alert('O campo Data Inicio deve ser preenchido');
        }

        var contract = sessionStorage.getItem('contract');        
        if(contract === null || contract === "" || contract === "null"){
            return window.alert('Acesso não permitido para fazer determinada ação.');
        }

        var data = {
            'Rentability': txtRetability,
            'ClosingMonth': txtClosingMonth,
            'DatePayments': txDatePayments,
            'Ipca': boolIPCA,
            'ContractHash': contract
        };
        
        // agora fica para fazer a requisição para o back-end 
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };       

        partnerAPI.post("Partner/Debenture_Update", data,config)
        .then((res) => {
            console.log(res.data, 'SUCCESS');

            if(res.data.success === false){
                let text = "";
                for(let prop in res.data.erros){
                    text += `${prop} : ${res.data.erros[prop]}` + "\n";
                }                
                window.alert(text);                                                 
            }else{
                window.alert('Fluxo processado com sucesso!');                                
            }
        })            
        .catch(error => {
            window.alert('Ocorreu algum erro no processamento!');                
            console.log(error.response, 'ERRO');
        });

      };

    return (
        <div className='row'>
            <div className='col-sm-5' style={{marginLeft: 25}}>
                <div className='container my-5 card'>
                
                <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                    <h5>Rentabilidade</h5>
                </div>

                <div className='container'>
                    <div className='row border border-2' style={{marginBottom: 15, borderRadius: 20}}>
                        <div className=''>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12'
                                    type="number"
                                    label="% de Rentabilidade" 
                                    variant="standard" 
                                    value={txtRetability} 
                                    onChange={e => setTxtRetability(e.target.value)} 
                                />
                            </div>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12' 
                                    label="Meses de Fechamento" 
                                    type="number"
                                    variant="standard" 
                                    value={txtClosingMonth} 
                                    onChange={e => setTxtClosingMonth(e.target.value)} 
                                />
                            </div>
                            <div style={{margin: 20}}>
                                <TextField className='col-sm-12' 
                                    label="Data Inicio Pagamento" 
                                    type="date"
                                    variant="standard" 
                                    value={txDatePayments} 
                                    onChange={e => setTxtDatePayments(e.target.value)} 
                                />
                            </div>
                            <div style={{margin: 20}}>
                                <FormControlLabel  control={<Switch checked={boolIPCA}  onChange={e => setBoolIPCA(e.target.checked)} />} label="+ IPCA" />
                            </div>
                        </div>                      
                        <div style={{position:"relative"}}>
                            <div style={{float:"right", margin:"10px"}}>                                                        
                                <Button variant="contained" onClick={handleClickSalvar}>Salvar</Button>                            
                            </div> 
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-sm-6'></div>
        </div>

    );
}