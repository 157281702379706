
import React , {  createContext, useState} from "react";

export const ConfigLayoutContext = createContext();     


export const ConfigLayoutProvider = ({children}) =>{
    const [configLayout, setConfigLayout] = useState({
        NameNav:"",
        colorsLayout: {
            colorInsideNav: "",
            colorInsideFooter: "",
            colorInsideDrawer: "",
            colorInsideButtons: "",
        },
        imagesLayout: {
            logoFront: "",
            logoFrontSquare: "",
            logoFooter: "",
        },
    });

    return (
       <ConfigLayoutContext.Provider value={{configLayout, setConfigLayout}}>
        {children}
       </ConfigLayoutContext.Provider>  
    )
}