    import React ,{useEffect, useState, useContext }  from "react";
    import { useNavigate, useSearchParams } from "react-router-dom";
    import partnerAPI from "../../../Services/partnerAPI";
    import { ConfigLayoutContext } from "../../../Context/configLayoutContext";

    //material
    import Card from "@mui/material/Card";
    import CardContent from "@mui/material/CardContent";
    import MenuIcon from '@mui/icons-material/Menu';
    import TextField from "@mui/material/TextField";
    import WalletIcon from '@mui/icons-material/Wallet';
    import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
    import SavingsIcon from '@mui/icons-material/Savings';
    import Button from "@mui/material/Button";
    import Stack from "@mui/material/Stack";
    import CircularProgress from '@mui/material/CircularProgress';
    
 
    import { ToastContainer, toast } from 'react-toastify';
    import { add } from "date-fns/fp/add";

    export default function ConfigLayout () {

        useEffect(()=>{
             const savedColors = JSON.parse(sessionStorage.getItem('colorsImageLayout'));
            if (savedColors) {
                setColorInsideNav(savedColors.colorsLayout.colorInsideNav);
                setColorInsideFooter(savedColors.colorsLayout.colorInsideFooter);
                setColorInsideDrawer(savedColors.colorsLayout.colorInsideDrawer);
                setColorInsideButtons(savedColors.colorsLayout.colorInsideButtons);
                setLogoFront(savedColors.imagesLayout.logoFront);
                setLogoFrontSquare(savedColors.imagesLayout.logoFrontSquare);
                setLogoFooter(savedColors.imagesLayout.logoFooter);  
                setConfigLayout(savedColors)
                console.log(configLayout, 'cores salvas na session')
            }    
        },[])
        const { configLayout, setConfigLayout } = useContext(ConfigLayoutContext);
        const [colorInsideNav, setColorInsideNav] = useState("");
        const [colorInsideFooter , setColorInsideFooter] = useState('');
        const [colorInsideDrawer , setColorInsideDrawer] = useState('');
        const [colorInsideButtons  , setColorInsideButtons] = useState('');
        const [colorInsideLogo , setColorInsideLogo] = useState('');
        const [colorInsideButton , setColorInsideButton] = useState('');
        const [logoFront , setLogoFront] = useState('');
        const [logoFrontSquare , setLogoFrontSquare] = useState('');
        const [logoFooter , setLogoFooter] = useState('');
        const [loading , setLoading] = useState(false);
         const [searchParamsQueryString] = useSearchParams();
         const [queryStringToken] = useState(searchParamsQueryString.get("token"));
        const [colors , setColors] = useState({
            colorInsideNav:"",
            colorInsideFooter:"",
            colorInsideDrawer:"",
            colorInsideButtons:"",
        });

        const navigate = useNavigate();

        const handleSavedColors = async (e)=>{ 
            setLoading(true)

            if(e.target){
                const colorsSaved= {
                    NameNav:"Teste",
                    colorsLayout:{            
                        colorInsideNav: colorInsideNav,
                        colorInsideFooter:  colorInsideFooter,
                        colorInsideDrawer: colorInsideDrawer,
                        colorInsideButtons: colorInsideButtons, 
                    },
                    imagesLayout:{
                        logoFront:logoFront,
                        logoFrontSquare:logoFrontSquare,
                        logoFooter: logoFooter,            
                    }
                };
                setColors(colorsSaved);
                setConfigLayout(colorsSaved)

                sessionStorage.setItem('colorsImageLayout', JSON.stringify(colorsSaved));
                //console.log('Cores Do Layout', (colorsSaved))                      
               
                try{
                    const dados = [
                        {
                            cryptoUserId: 28,  
                            nrTypeLayout: 3,
                            nmLayout: "Color Inside Nav",
                            nmColor:colorInsideNav, 
                            nrImage: 1,      
                            nmImage: "Logo Front"            
                        },
                        {
                            cryptoUserId: 28, 
                            nmPartnerPerson: "casa do credito",
                            dsTokenPartnerConfigure: "contractCiPay7a5df5ffa0dec2228d90b8d0a0f1b0767b748b0a41314c123075b8289e4e053f", 
                            nrTypeLayout: 4,
                            nmLayout: "Color Inside Drawer",
                            nmColor:colorInsideDrawer,
                            dsImageUrl: "https://i.ibb.co/1G2rGW8/logo-Frot-removebg-preview.png",
                            nrImage: 1,      
                            nmImage: "Logo Front"
                            
                        }, 
                        {
                            cryptoUserId: 28, 
                            nmPartnerPerson: "casa do credito",
                            dsTokenPartnerConfigure: "contractCiPay7a5df5ffa0dec2228d90b8d0a0f1b0767b748b0a41314c123075b8289e4e053f", 
                            nrTypeLayout: 5,
                            nmLayout: "Color Inside Footer",
                            nmColor:colorInsideFooter,
                            dsImageUrl: "https://i.ibb.co/1G2rGW8/logo-Frot-removebg-preview.png",
                            nrImage: 1,      
                            nmImage: "Logo Front"
                            
                        }, 
                        {
                            cryptoUserId: 28, 
                            nmPartnerPerson: "casa do credito",
                            dsTokenPartnerConfigure: "contractCiPay7a5df5ffa0dec2228d90b8d0a0f1b0767b748b0a41314c123075b8289e4e053f", 
                            nrTypeLayout: 6,
                            nmLayout: "Color Inside Buttons",
                            nmColor:colorInsideButtons,
                            dsImageUrl: "https://i.ibb.co/1G2rGW8/logo-Frot-removebg-preview.png",
                            nrImage: 1,      
                            nmImage: "Logo Front"
                        
                        }, 
                    ]
                    
                    const config = {
                        headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token"),
                        
                        },
                    };                   
        
                    const addConfigColorsAndImages = await partnerAPI.post("/Configure/LayoutPartner",dados, config)
                    if (addConfigColorsAndImages){
                        toast.success('Cores salvas com sucesso');
                        console.log(addConfigColorsAndImages, 'cores enviadas')
                    }
                }catch(error){
                    console.log(error, 'deu erro')
                }
            }
            setTimeout(() => {
            setLoading(false);
            navigate();
                
            }, 1000);     
        }

        return (
            <>
                <div className="col-sm-5 m-4">
                    {loading === true ? (
                <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row"  >
                  <Card className=' col-sm-12' style={{ height:500}}>
                      <CircularProgress   size={50} style={{ margin :'30% 50% 50% 40%'  }}  />  
                  </Card>      
                </Stack>
                    ):(
                        <>
                         <div className="m-3">
                        <h5>Configuração do Layout</h5>
                    </div>
                    <Card className="col-sm-8 ">
                        <CardContent>
                            <Card className="pt-1 pb-4 mt-2" >
                                <div className="p-2 mb-2"> Barra de Navegação</div>
                                <div className=" row text-center">
                                    <div className=" col-sm-6 d-flex align-items-center" >
                                        <div
                                        style={{
                                            //"#05134B"
                                            backgroundColor:colorInsideNav  ,
                                            padding:"10px 5px 5px 5px",
                                            width:'100%',
                                            height:'40px',
                                            marginRight:"30px",
                                            marginLeft:'5px'
                                            }}>
                                            <h6 style={{color:'white', fontSize:'13px'}}>  
                                                <MenuIcon style={{width:"px"}}/> {configLayout.NameNav}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField
                                        variant="standard"
                                        label="# color"
                                        type='color'
                                        value={colorInsideNav}
                                        onChange={(e)=>setColorInsideNav(e.target.value)}
                                        style={{width:60}}
                                        />
                                    </div>
                                </div>
                            </Card>

                            
                            <Card className="pt-1 pb-4 mt-2 ">
                                <div className="p-2">Rodapé</div>
                                <div className=' row text-center'>
                                    <div className=" col-sm-6   align-items-center " >
                                        <div style={{
                                            //'#191919'
                                            backgroundColor:colorInsideFooter,
                                            height:'100px',
                                            width:'85%',
                                            marginRight:'30px',
                                            marginLeft:'5px'
                                        }}>
                                            <div className="d-flex flex-row-reverse">
                                                <img src={logoFooter}
                                                style={{
                                                    width: 15,
                                                    paddingTop:'10px',
                                                    marginRight:'5px'
                                                    }} />
                                            </div>
                                        </div>

                                    </div>
                                        <div className="col-sm-6">

                                            <TextField
                                                variant="standard"
                                                label="# color"
                                                type='color'
                                                value={colorInsideFooter}
                                                style={{width:60}}
                                                onChange={(e)=>setColorInsideFooter(e.target.value)}
                                            />
                                        </div>
                                </div>
                            </Card>

                            <Card className="pt-1 pb-4 mt-2">
                                <div className="p-2">Ícones de Navegação</div>
                                <div  className='row text-center'>
                                    <div className="col-sm-6   align-items-center ">
                                        <div style={{
                                            //'#FF5616'
                                            backgroundColor:colorInsideDrawer,
                                            width:'47px',
                                            height:'110px',
                                            marginRight:'60px',
                                            marginLeft:55,
                                            
                                        }}>
                                            <div style={{ paddingTop:10 }}><WalletIcon/></div>
                                            <div style={{ paddingTop:10 }}><AttachMoneyIcon/></div>
                                            <div style={{ paddingTop:10 }}><SavingsIcon/></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                            <TextField
                                            variant="standard"
                                            label="# color"
                                            type='color'
                                            value={colorInsideDrawer}
                                            style={{width:60}}
                                            onChange={(e)=>setColorInsideDrawer(e.target.value)}
                                            />
                                    </div>
                                </div>
                            </Card>

                            <Card className="pt-1 pb-4 mt-2">
                                <div className="p-2">Botões</div>
                                <div className='row text-center'>
                                    <div className="col-sm-6   align-items-center">
                                        <div style={{
                                            marginRight:'60px',
                                            
                                    
                                        }}
                                        >
                                            <div>
                                                <Button color="secondary" variant="contained" style={{
                                                    backgroundColor: colorInsideButtons,
                                                }}>
                                                    Botão
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField
                                            variant="standard"
                                            label="# color"
                                            type='color'
                                            value={colorInsideButtons}
                                            style={{width:60}}
                                            onChange={(e)=>setColorInsideButtons(e.target.value)}
                                        />

                                    </div>
                                </div>

                            </Card>

                            <div className="d-flex flex-row-reverse mt-3">                               
                                    <Button style={{backgroundColor: colorInsideButtons}}  variant="contained"
                                    onClick={handleSavedColors}>
                                        Salvar
                                    </Button>
                              
                            </div>
                            <div>
                                  <ToastContainer />
                            </div>

                        </CardContent>
                    </Card>
                        </>
                    )}
                   

                </div>        
            </>
        )

    }