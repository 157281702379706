import React , { useState, useContext, createContext, useEffect} from "react" 
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';

import  {useDrawerContext}  from "../../../Context/drawerContext";
import authContext from "../../../Context/authContext";
import accessLoginWhiteLabelContext from '../../../Context/accessLoginWhiteLabelContext';
import firstLoginWhiteLabelContext from '../../../Context/firstLoginWhiteLabelContext';

// material 
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
//

//icons
import WalletIcon from '@mui/icons-material/Wallet';
import MuiDrawer from '@mui/material/Drawer';
import SavingsIcon from '@mui/icons-material/Savings';
import HelpIcon from '@mui/icons-material/Help';
import ExploreIcon from '@mui/icons-material/Explore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LogoutIcon from "@mui/icons-material/Logout"

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NavDrawer (){   

  useEffect(() => {
    
    configColors();

  });

  const theme = useTheme();
  const { drawerOpen, setDrawerOpen, drawerWidth, setDrawerWidth, configProducts } = useDrawerContext();
  const {accessLoginWhiteLabel, setAccessLoginWhiteLabel} = useContext(accessLoginWhiteLabelContext);
  const { isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel } = useContext(firstLoginWhiteLabelContext);
  

  const [colorInsideDrawer , setColorInsideDrawer] = useState('');
  const [colorInsideNav , setColorInsideNav] = useState('');

  const [open , setOpen] = useState (drawerOpen);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setDrawerWidth(180)
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setDrawerWidth(56);
  };

    const IrParaPageExplorer = () => {
    window.open("https://explorer.cipay.com.br", "_blank");
      setDrawerOpen(false);
      setDrawerWidth(56);
  };
  const navigate = useNavigate();
  const { authenticated, setAuthenticated } = useContext(authContext);
 

  const logout = () => {

    let route = sessionStorage.getItem("contract");

    if(route === "null"){
      console.log('if(route === null)')
      setAuthenticated(false);
      sessionStorage.clear();
      return;
    }

    let newRoute = "/?token=" + route;
    navigate(newRoute);
    setAuthenticated(false);
    sessionStorage.clear();
}
const configColors = () => {
  //debugger
  const colorsLayout = (sessionStorage.getItem('colorsImageLayout'));
  if (colorsLayout) {
    const parseColorDrawer = JSON.parse(colorsLayout)
    if (parseColorDrawer){
      const colorDrawer = {
      colorInsideDrawer:parseColorDrawer.colorsLayout.colorInsideDrawer,       
      colorInsideNav: parseColorDrawer.colorsLayout.colorInsideNav,
    
      
    }
      setColorInsideDrawer(colorDrawer.colorInsideDrawer);
      setColorInsideNav(colorDrawer.colorInsideNav)

    }
    // setColorInsideNav(colorsLayout.colorInsideNav)
    //console.log('cores Drawer', colorInsideDrawer) ; 
  }
};

 
  return (
    <>
    <accessLoginWhiteLabelContext.Provider value={{accessLoginWhiteLabel, setAccessLoginWhiteLabel}}>
    <firstLoginWhiteLabelContext.Provider value={{ isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel }}>
    <CssBaseline  />
      <AppBar position="fixed" open={drawerOpen}
      style={{ backgroundColor: colorInsideNav}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 4,
              ...(drawerOpen && { display: 'none' }),
            }}            
          >            
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div"
            onClick={() => navigate("/home")}
            style={{cursor:'pointer'}}
            
          >
          <div onClick={handleDrawerClose} >CIPay</div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={drawerOpen} anchor="" style={{
        
      }}>
        <DrawerHeader style={{ backgroundColor: colorInsideDrawer}}>
          <IconButton   onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            <span style={{fontSize:14}}>Fechar</span>
          </IconButton>
        </DrawerHeader>
        <List
         onClick={handleDrawerClose}
         style={{ backgroundColor: colorInsideDrawer, height: '100%',}}
         > 
          <div  className=" container" style={{ }} >
            { !isfirstLoginWhiteLabel && (
              <>
              <div style={{padding:'10px 0px',  borderBottom:"solid  1px #fff"}}>
                  <MenuItem onClick={() => navigate("/home")}>
                    {drawerOpen === true ?(
                      <div style={{transition: 'margin-left 0.3s'}}>
                        <WalletIcon style={{ marginRight: 30, width:30, height:30}} alt='Wallet'/>
                        <b>Wallet</b>
                      </div>
                    ):(
                    <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                      <WalletIcon style={{ width:30, height:30}}/>
                    </div>
                    )}
                  </MenuItem>
              </div>
              {configProducts.actionWithdraw === true ? (
                <div  style={{padding:'10px 0px', borderBottom:"solid 0.1px #ffff"}}>
                  <MenuItem
                      onClick={() => navigate("/saque")}
                      style={{ padding: 12 }}
                  >             
                    {drawerOpen === true ?(
                      <div style={{ display:'block',transition: 'margin-left 0.3s'}}>
                        <AttachMoneyIcon style={{ marginRight: 30, width:30, height:30}}/>
                        <b>Saque</b>
                      </div>
                    ):(                        
                    <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                        <AttachMoneyIcon style={{ width:30, height:30}}/>       
                    </div>
                    )}          
                  </MenuItem>
                </div>
              ):(<></>)} 

              {configProducts.actionDeposit === true ? (
                <div  style={{padding:'10px 0px', borderBottom:"solid 0.1px #ffff"}}>
                  <MenuItem
                      onClick={() => navigate("/deposito")}
                      style={{ padding: 12 }}
                  >           
                    {drawerOpen === true ?(
                      <div style={{ display:'block',transition: 'margin-left 0.3s'}}>
                        <SavingsIcon style={{ marginRight: 30, width:30, height:30}}/>
                        <b>Deposito</b>
                      </div>
                    ):(                        
                    <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                        <SavingsIcon style={{ width:30, height:30}}/>       
                    </div>
                    )}          
                  </MenuItem>
              </div>
              ):(<></>)}         
                
              <div  style={{padding:'10px 0px', borderBottom:"solid 0.1px #ffff"}}>
                  <MenuItem
                      onClick={() => navigate("/ajuda")}
                      style={{ padding: 12 }}
                  >           
                    {drawerOpen === true ?(
                      <div style={{transition: 'margin-left 0.3s'}}>
                        <HelpIcon style={{ marginRight: 30, width:30, height:30}}/>
                        <b>Ajuda</b>
                      </div>
                    ):(                        
                    <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                        <HelpIcon style={{ width:30, height:30}}/>
                      
                    
                    </div>
                    )}          
                  </MenuItem>
              </div>

              <div  style={{padding:'10px 0px', borderBottom:"solid 0.1px #ffff"}}>
                  <MenuItem
                      onClick={() => IrParaPageExplorer()}
                      style={{ padding: 12 }}
                  >               
                    {drawerOpen === true ?(
                      <div style={{transition: 'margin-left 0.3s'}}>
                        <ExploreIcon style={{ marginRight: 30, width:30, height:30}}/>
                        <b>Explorer</b>
                      </div>
                    ):(
                      
                    <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                        <ExploreIcon style={{ width:30, height:30}}/>                        
                    
                    </div>
                    )}    
                  </MenuItem>
              </div>

              {accessLoginWhiteLabel > 0 &&
                <div  style={{padding:'10px 0px', borderBottom:"solid 0.1px #ffff"}}>
                    <MenuItem
                        onClick={() => navigate("/configuracoes")}  
                        style={{ padding: 12 }}
                    >               
                      {drawerOpen === true ?(
                        <div style={{transition: 'margin-left 0.3s'}}>
                          <SettingsIcon style={{ marginRight: 10, width:30, height:30}}/>
                          <b>Configuração</b>
                        </div>
                      ):(
                        
                      <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                        <SettingsIcon style={{ width:30, height:30}}/>                        
                      
                      </div>
                      )}    
                    </MenuItem>
                </div>
              }
              </>
            )}

            <div  style={{paddingBottom:20}}>
                <MenuItem
                    onClick={() => logout()}
                    style={{ padding: 12, marginTop:50 }}

                >
                  {drawerOpen === true ?(
                    <div style={{transition: 'margin-left 0.3s'}}>
                      <LogoutIcon style={{ marginRight: 30, width:30, height:30}}/>
                      <b>Sair</b>
                    </div>
                  ):(
                    
                  <div style={{display:'block', marginLeft:'-12px',transition: 'margin-left 0.3s'}}>
                      <LogoutIcon style={{ width:30, height:30}}/>   
                  </div>
                  )}   

                </MenuItem>
            </div>

          </div>
        </List>
      </Drawer>    
      <DrawerHeader />    
    </firstLoginWhiteLabelContext.Provider>
    </accessLoginWhiteLabelContext.Provider>
    </>
  );
}